import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Logo from "../../assets/logo.svg";
import Alert from "../../components/Alert";
import Aside from "../../components/Aside";
import styles from "../Login/style.module.css";
import OTPSuccess from "./final";

const OTP = ({ title = "" }) => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const history = useHistory();
	const route = useRouteMatch();
	const location = useLocation();

	const [uid, setUID] = React.useState("");
	const [code, setCode] = React.useState("");
	const [fetching, setFetching] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState();

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const parseQuery = async () => {
		const query = new URLSearchParams(location.search).get("query");
		if (query) {
			try {
				const values = JSON.parse(atob(query));

				history.push(location.pathname);

				setFetching(true);
				const { success } = await axios
					.put(`/auth/otp`, values)
					.then((res) => res?.data?.data);

				setFetching(false);
				if (!success) {
					throw new Error("OTP verification failed.");
				}

				if (/phone/i.test(route.path)) {
					history.push("/otp-email", {
						signUpId: location.state?.signUpId,
						accountType: location.state?.accountType
					});
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message: "Phone number verified successfully."
					});
				} else {
					setSuccessMessage(
						"Email verified successfully. Click log in to continue"
					);
				}
			} catch (err) {
				setFetching(false);
				const message = err?.response?.data?.error || err.message;
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message
				});
				console.error(err);
			}
		}
	};

	React.useEffect(() => {
		parseQuery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setUID("");
		setCode("");
	}, [title]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const { success } = await axios
				.put(`/auth/otp`, {
					uid,
					code,
					recId: location.state?.signUpId
				})
				.then((res) => res?.data?.data);

			setFetching(false);
			if (!success) {
				throw new Error("OTP verification failed.");
			}

			if (/phone/i.test(route.path)) {
				history.push("/otp-email", {
					signUpId: location.state?.signUpId,
					accountType: location.state?.accountType
				});
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Phone number verified successfully."
				});
			} else {
				setSuccessMessage(
					`Email verified successfully. Click ${
						userInfo?.accountType === "corporate" ? "log in to" : ""
					} continue`
				);
			}
		} catch (err) {
			setFetching(false);
			const message = err?.response?.data?.error || err.message;
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message
			});
		}
	};

	const resendOTP = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const { success } = await axios
				.post(`/auth/otp?type=${title.toLowerCase()}`, {
					uid
				})
				.then((res) => res?.data?.data);

			setFetching(false);
			if (!success) {
				throw new Error("OTP resend failed.");
			}
		} catch (err) {
			setFetching(false);
			const message = err?.response?.data?.error || err.message;
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message
			});
			console.error(err);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside showOnMobile={false} />
			{successMessage && /email/i.test(route.path) ? (
				<OTPSuccess
					message={successMessage}
					accountType={location.state.accountType}
				/>
			) : (
				<main className={styles.auth__form__container}>
					<img src={Logo} alt="logo" />
					<h1 className={styles.heading__text}>
						{title} Verification
					</h1>
					{/phone/i.test(route.path) ? (
						<p className={styles.sub__heading__text}>
							An OTP has been sent to your phone, kindly key it in
							the text to verify your phone number.
						</p>
					) : (
						<p className={styles.sub__heading__text}>
							Log into your email and click the{" "}
							<b>"Confirm Account"</b> link to continue the
							registration process.
						</p>
					)}
					<form className={styles.auth__form} onSubmit={handleSubmit}>
						<label htmlFor="uid">
							{title}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="uid"
							type="text"
							name={/email/i.test(title) ? "email" : "text"}
							value={uid}
							onChange={(e) => setUID(e.target.value)}
							placeholder={
								/email/i.test(title)
									? "john.doe@gmail.com"
									: "+234901923003944"
							}
							required
							disabled={fetching}
						/>
						<label htmlFor="code">
							OTP
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="code"
							type="text"
							name="code"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							placeholder="435344"
							required
							disabled={fetching}
						/>
						<button
							type="submit"
							className={[
								"primary__btn",
								styles.submit__button
							].join(" ")}
							style={{ marginTop: "14px" }}
							disabled={!code || !uid}
						>
							{fetching ? "Verifying..." : "Verify"}
						</button>
						{uid && (
							<p>
								<button
									onClick={resendOTP}
									className="link__button"
									type="button"
								>
									Resend OTP code
								</button>
							</p>
						)}
					</form>
				</main>
			)}
		</section>
	);
};

export default OTP;
