import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import arrowRight from "../../assets/Home/arrowRight.svg";
import processSteps from "../../assets/Home/processSteps.svg";
import registerImage from "../../assets/Home/registerImage.svg";
import serviceTypeImage1 from "../../assets/Home/serviceTypeImage1.svg";
import serviceTypeImg2 from "../../assets/Home/serviceTypeImg2.svg";
import serviceTypeImg3 from "../../assets/Home/serviceTypeImg3.svg";
import serviceTypeImg4 from "../../assets/Home/serviceTypeImg4.svg";
import showMeOnScreen from "../../assets/Home/showMeOnScreen.svg";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
import styles from "./styles.module.css";

const cardContents = [
	{
		id: 1,
		image: serviceTypeImage1,
		alternate: "Tax type image",
		heading: "Tax Types",
		subHeading:
			"Defines the various types of tax collected by the different taxation bodies.",
		link: "Learn more",
		route: "/tax-types"
	},

	{
		id: 2,
		image: serviceTypeImg2,
		alternate: "Tax system image",
		heading: "Tax Systems",
		subHeading:
			"Describes the methods of Tax Administration which could be Proportional, Progressive or Regressive.",
		link: "Learn more",
		route: "/tax-systems"
	},

	{
		id: 3,
		image: serviceTypeImg3,
		alternate: "Tax collection image",
		heading: "Tax Collection",
		subHeading:
			"Tax collection involves the enforcement of tax policies and laws established by a government institution to generate and consolidate revenue for governmental use.",
		link: "Learn more",
		route: "/tax-collection"
	},
	{
		id: 4,
		image: serviceTypeImg4,
		alternate: "Tax legislation image",
		heading: "Tax Legislation",
		subHeading:
			"Tax legislation are the acts governing Taxation in Nigeria for various tax types.",
		link: "Learn more",
		route: "/tax-legislation"
	}
];

const TaxCategory = () => {
	const history = useHistory();
	useIntersectionObserver();

	return (
		<main>
			<section className={styles.TaxProcess}>
				<h2 className={styles.title}>
					Services that simplify the{" "}
					<span className={styles.underlineGreen}>&nbsp;process</span>
				</h2>
				<p className={styles.subTitleDesktop}>
					Accessing the necessary information to file your taxes has
					been greatly <br /> simplified and made more convenient
				</p>
				<p className={styles.subTitleMobile}>
					Accessing the necessary information to file your taxes has
					been greatly simplified and made more convenient
				</p>
				<div className={styles.cards}>
					{cardContents.map((cardContent, index) => (
						<div
							className={styles.cardContents}
							key={cardContent.id}
						>
							<img
								src={cardContent.image}
								alt={cardContent.alternate}
							/>
							<h3 className={styles.cardHeading}>
								{cardContent.heading}
							</h3>
							<p className={styles.cardText}>
								{cardContent.subHeading}
							</p>
							<Link
								to={
									index === 0
										? "/tax-type"
										: index === 1
											? "/tax-system"
											: index === 2
												? "/tax-collection"
												: "/tax-legislation"
								}
								className={styles.learnMore}
							>
								{cardContent.link}
							</Link>
						</div>
					))}
				</div>
			</section>

			<section className={styles.procedures}>
				<img
					src={showMeOnScreen}
					alt="Process"
					className={`${styles.showMeOnScreen} ${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
				/>
				<div
					className={`${styles.procedureContent} ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
				>
					<h2 className={styles.procedureTitleDesktop}>
						<span className={styles.underlineGold}>Stream</span>
						lined &nbsp;and clear-cut procedures
					</h2>

					<h2 className={styles.procedureTitleMobile}>
						Streamlined&nbsp;and clear-cut{" "}
						<span className={styles.underlineGold}>procedures</span>
					</h2>
					<p className={styles.procedureText}>
						As Enugu State moves towards meeting the needs of a mega
						city, EIRS continues to position itself as the major
						funding arm of the state government by putting in place
						structures that will ensure collection of the proper
						amount of tax revenue at the least cost.
					</p>
					<button onClick={() => history.push("/about-us")}>
						<div>
							Learn more about us{" "}
							<img src={arrowRight} alt="arrow Right Icon" />
						</div>
					</button>
				</div>
			</section>
			<section className={styles.registerProcess}>
				<img
					src={registerImage}
					alt="register"
					className={`${styles.baldMan} ${observerStyle.slideInRight} ${observerStyle.animateHomePage}`}
				/>
				<div
					className={`${styles.process} ${observerStyle.slideInLeft} ${observerStyle.animateHomePage}`}
				>
					<h2>
						How to
						<span className={styles.underlineGreen}>
							&nbsp;register
						</span>
					</h2>
					<h3>You can get started easily with three simple steps</h3>
					<img
						src={processSteps}
						alt="process Steps"
						className={styles.processSteps}
					/>
					<button onClick={() => history.push("/signup")}>
						Click here to get your Social ID
					</button>
				</div>
			</section>
		</main>
	);
};

export default TaxCategory;
