// import styles from "../Home/style.module.css";
import axios from "axios";
import { useState } from "react";

import ArrowRight from "../../assets/Home/arrow-right.svg";
import constants from "../../constants";
import styles from "./styles.module.css";

const Subscription = () => {
	const [email, setEmail] = useState("");
	const [subscribing, setSubscribing] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const sendNewsLetter = async (e) => {
		setSubscribing(true);
		try {
			const response = await axios.post(
				`${constants.BASE_URL_2}/subscribe/newsletter`,
				{ email: email }
			);
			if (response.data.description.status === 200) {
				if (errorMessage) {
					setErrorMessage("");
				}
				setSuccessMessage(response.data.message);
			} else if (response.data.description.status === 201) {
				if (errorMessage) {
					setErrorMessage("");
				}
				setSuccessMessage(response.data.message);
			}
		} catch (err) {
			setSuccessMessage("");
			setErrorMessage(
				err?.response?.data?.message ??
					"Something went wrong, Please try again."
			);
		} finally {
			setSubscribing(false);
		}
	};

	return (
		<>
			<section className={styles.subscription}>
				<h2>Subscribe to our Newsletter</h2>
				<p>
					Keep updated on the latest events, tax discounts, and news
					in Enugu by subscribing to our newsletter.
				</p>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						sendNewsLetter();
					}}
					className={styles.inputAreaMobile}
				>
					<input
						type="email"
						value={email}
						placeholder="Enter your email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<button type="submit" disabled={!email || subscribing}>
						{subscribing ? (
							"Subscribing ..."
						) : (
							<>
								Subscribe
								<img
									src={ArrowRight}
									alt="Subscription Arrow"
								/>
							</>
						)}
					</button>
				</form>
				{/* <p style={{ color: "#34AA63", fontSize: "10px" }}>
					{successMessage ? successMessage : ""}
				</p> */}
				{/* <p
					className={styles.errorMessage}
					style={{ color: "red", fontSize: "12px", marginTop: "0" }}
				>
					{errorMessage ? errorMessage : ""}
				</p> */}
				{successMessage && (
					<p
						style={{
							color: "#34AA63",
							fontSize: "16px"
						}}
					>
						{successMessage}
					</p>
				)}

				{errorMessage && (
					<p
						style={{
							color: "red",
							fontSize: "16px"
						}}
					>
						{errorMessage}
					</p>
				)}
			</section>
		</>
	);
};

export default Subscription;
