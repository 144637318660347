import axios from "axios";
import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../assets/Home/closeIcon.svg";
import styles from "./style.module.css";
// import Alert from '../../components/Alert';

const TaxCalcInputter = () => {
	const [showTaxCalcResult, setShowTaxCalcResult] = useState(false);
	const [loading, setLoading] = useState(false);
	const [income, setIncome] = useState("");
	const [data, setData] = useState({
		taxData: null,
		allData: []
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const handleIncomeTaxCalc = async (e) => {
		e.preventDefault();
		if (!!income && !isNaN(income)) {
			try {
				setLoading(true);
				const taxResult = await axios
					.get(`taxcalculator/${income}`)
					.then((res) => (res.data || {}).data);
				if (taxResult) {
					setShowTaxCalcResult(true);
					setLoading(false);
					setAlert({
						showing: true,
						type: "success",
						message: "ChargeTaxSpread found"
					});
					const sorted = taxResult.sort(
						(a, b) =>
							parseInt(a.incTranchNo) - parseInt(b.incTranchNo)
					);
					let sortedObject = {};
					sorted.forEach((obj) => {
						sortedObject[obj.incTranchNo] = obj;
					});
					setData({
						allData: taxResult,
						taxData: sortedObject
					});
				}
			} catch (err) {
				setLoading(false);
				setAlert({
					showing: true,
					type: "error",
					message:
						err?.response?.data?.msg ??
						"Tax Calculation not successful."
				});
			}
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			<div className="container demo">
				<div
					className="modal right fade"
					id="myModal2"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="myModalLabel2"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className={`modal-body ${styles.modal__body}`}>
								<div>
									<CloseIcon
										type="button"
										data-dismiss="modal"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setData({
												allData: [],
												taxData: null
											});
											setShowTaxCalcResult(false);
										}}
									/>
									<h3>Tax Calculator</h3>
								</div>
								{!showTaxCalcResult ? (
									<>
										<label htmlFor="incomeField">
											Annual Income{" "}
											<span className="text-danger">
												*
											</span>
										</label>
										<div className="input-group">
											<span
												className="input-group-addon"
												id="sizing-addon1"
											>
												&#8358;
											</span>
											<input
												type="text"
												id="incomeField"
												className="form-control"
												placeholder="What's your annual income?"
												aria-describedby="sizing-addon2"
												onChange={(e) =>
													setIncome(e.target.value)
												}
											/>
										</div>

										<button
											className={`btn btn-success ${styles.my_30}`}
											type="button"
											disabled={!income}
											onClick={handleIncomeTaxCalc}
										>
											{loading
												? "Calculating..."
												: "Calculate my Taxes"}
										</button>

										{alert.showing && (
											<div
												className={
													alert.type === "success"
														? [
																styles.sidebar__alert,
																styles.success
															].join(" ")
														: alert.type === "error"
															? [
																	styles.sidebar__alert,
																	styles.error
																].join(" ")
															: styles.sidebar__alert
												}
											>
												{alert.message}
											</div>
										)}
									</>
								) : (
									<>
										<p>
											Instantly estimate your tax due with
											the calculator.
										</p>

										<br />
										<h4>Income-to-Tax</h4>
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Annual Total Income</th>
													<th>
														₦
														{Number(
															income
														).toLocaleString(
															"en-US"
														)}
													</th>
												</tr>
												<tr>
													<th>
														Annual Taxable Income
													</th>
													<th>
														{data?.allData
															?.length &&
														data?.allData[
															data?.allData
																?.length - 1
														]?.totTaxableIncome
															? `₦${Number(
																	data
																		?.allData[
																		data
																			?.allData
																			?.length -
																			1
																	]
																		?.totTaxableIncome
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</th>
												</tr>
											</thead>
										</table>
										<br />

										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Tax Band</th>
													<th>Rate</th>
													<th>
														{"Tax Calculated(₦)"}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["1"]
															? `First NGN ${Number(
																	data
																		?.taxData[
																		"1"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["1"]
															? `${Number(
																	data
																		?.taxData[
																		"1"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["1"]
															? `₦${Number(
																	data
																		?.taxData[
																		"1"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>
												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["2"]
															? `Next NGN ${Number(
																	data
																		?.taxData[
																		"2"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["2"]
															? `${Number(
																	data
																		?.taxData[
																		"2"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["2"]
															? `₦${Number(
																	data
																		?.taxData[
																		"2"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>
												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["3"]
															? `Next NGN ${Number(
																	data
																		?.taxData[
																		"3"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["3"]
															? `${Number(
																	data
																		?.taxData[
																		"3"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["3"]
															? `₦${Number(
																	data
																		?.taxData[
																		"3"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>
												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["4"]
															? `Next NGN ${Number(
																	data
																		?.taxData[
																		"4"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["4"]
															? `${Number(
																	data
																		?.taxData[
																		"4"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["4"]
															? `₦${Number(
																	data
																		?.taxData[
																		"4"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>
												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["5"]
															? `Next NGN ${Number(
																	data
																		?.taxData[
																		"5"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["5"]
															? `${Number(
																	data
																		?.taxData[
																		"5"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["5"]
															? `₦${Number(
																	data
																		?.taxData[
																		"5"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>

												<tr>
													<td>
														{data?.taxData &&
														data?.taxData["6"]
															? `Above NGN ${Number(
																	data
																		?.taxData[
																		"6"
																	]?.incTranch
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["6"]
															? `${Number(
																	data
																		?.taxData[
																		"6"
																	]?.incRate
																).toLocaleString(
																	"en-US"
																)}%`
															: ""}
													</td>
													<td>
														{data?.taxData &&
														data?.taxData["6"]
															? `₦${Number(
																	data
																		?.taxData[
																		"6"
																	]?.tranchTax
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</td>
												</tr>
											</tbody>
										</table>
										<br />
										<table className="table table-bordered">
											<tfoot>
												<tr>
													<th>
														Total calculated annual
														Tax
													</th>
													<th>
														{data?.allData
															?.length &&
														data?.allData[
															data?.allData
																?.length - 2
														]?.totTaxCalc
															? `₦${Number(
																	data
																		?.allData[
																		data
																			?.allData
																			?.length -
																			2
																	]
																		?.totTaxCalc
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</th>
												</tr>
												<tr>
													<th>
														Total calculated Monthly
														Tax
													</th>
													<th>
														{data?.allData
															?.length &&
														data?.allData[
															data?.allData
																?.length - 2
														]?.totTaxCalc
															? `₦${Number(
																	data
																		?.allData[
																		data
																			?.allData
																			?.length -
																			2
																	]
																		?.totTaxCalc /
																		12
																).toLocaleString(
																	"en-US"
																)}`
															: ""}
													</th>
												</tr>
											</tfoot>
										</table>
										<br />
										<br />
										<div
											className={
												styles.modal__button__wrapper
											}
										>
											<button
												className={`${styles.modal__button} btn`}
												onClick={() => {
													setData({
														allData: [],
														taxData: null
													});
													setAlert({
														showing: false,
														type: null,
														message: ""
													});
													setShowTaxCalcResult(false);
												}}
											>
												Check again
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TaxCalcInputter;
