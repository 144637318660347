import { Link, useHistory } from "react-router-dom";

import arrowLeftIcon from "../../assets/arrowLeftIcon.svg";
import ESIRSLogo from "../../assets/logo.svg";
import pageNotFoundImg from "../../assets/PageNotFound/404.svg";
import styles from "./style.module.css";

const PageNotFound = () => {
	const history = useHistory();

	return (
		<>
			<header className={styles.header}>
				<img src={ESIRSLogo} alt="ESIRS Logo" />
			</header>
			<main className={styles.main}>
				<img src={pageNotFoundImg} alt="Page Not Found" />
				<h1>PAGE NOT FOUND</h1>
				<p>
					The page you are looking for might have been removed, had
					its name changed, is temporarily unavailable, never existed,
					or you do not have permission to view it.
				</p>
				<button onClick={() => history.goBack()} type="button">
					<img src={arrowLeftIcon} alt="Arrow Left" />
					Go Back
				</button>
				<button
					className="primary__btn"
					onClick={() => history.push("/home")}
					type="button"
				>
					Go Home
				</button>
				<Link to="/login">or Login</Link>
			</main>
		</>
	);
};

export default PageNotFound;
