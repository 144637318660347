import { useHistory, useLocation } from "react-router-dom";

import Success from "../assets/Signup/success.svg";
import Aside from "../components/Aside";
import styles from "./final.module.css";

const CorporateESBNRegSucess = () => {
	const location = useLocation();
	const history = useHistory();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	return (
		<main className={styles.success__page}>
			<Aside />
			<div className={styles.success}>
				<img
					src={Success}
					alt="hand holding a championship cup with some stars around"
				/>
				<h1>Successful</h1>
				<p>
					your ESBN is <b>{location.state}</b> and has been sent to
					phone number and email
				</p>
				<button
					className="primary__btn"
					onClick={() => {
						userInfo?.account_type === "corporate"
							? (window.location.href = "/")
							: history.push("/statetin/corporate");
					}}
				>
					{userInfo?.account_type === "corporate"
						? "Go to Dashboard"
						: "Go to corporate ESBNs"}
				</button>
			</div>
		</main>
	);
};

export default CorporateESBNRegSucess;
