import React from "react";
import { Link } from "react-router-dom";

import Facebook from "../../assets/Home/facebook.svg";
import { ReactComponent as FooterLogo } from "../../assets/Home/footer-logo.svg";
// Socials
import Instagram from "../../assets/Home/instagram.svg";
import Norlics from "../../assets/Home/Norlics.svg";
import X from "../../assets/Home/x.svg";
import constants from "../../constants";
import styles from "./style.module.css";

const Footer = () => {
	return (
		<>
			<div className={` ${styles.footer__home}`}>
				<div className="">
					<FooterLogo />
					<ul className="">
						<li className={`${styles.footer__item}`}>
							<Link to="#">
								Revenue Centre, State Secretariat Complex,
								Okpara Avenue, Opposite State CID Enugu, Enugu
								State, Nigeria.
							</Link>
						</li>
						<li className={`${styles.footer__item}`}>
							<a
								href="mailto: care@enugurevenueservice.gov.ng"
								style={{
									color: "rgba(0, 92, 184, 1)"
								}}
							>
								esirs@enugustate.gov.ng
							</a>
						</li>
						<li className={`${styles.footer__item}`}>
							<a href="tel:08075625555">08075625555</a>
						</li>
					</ul>
				</div>

				<div className="">
					<h5>QUICK LINKS</h5>
					<ul className={styles.menu_list}>
						<li className={`${styles.footer__item} `}>
							<Link to="/signup">Get ESBN</Link>
						</li>
						<li className={`${styles.footer__item}`}>
							<Link to="/login">Login to Enugu CMS</Link>
						</li>
						<li className={`${styles.footer__item}`}>
							<Link to="/reset-password">Lookup ESBN</Link>
						</li>
						<li
							className={`${styles.footer__item} `}
							type="button"
							data-toggle="modal"
							data-target="#myModal2"
						>
							<Link to="#">Tax Calculator </Link>
						</li>
					</ul>
				</div>

				<div className="">
					<h5>Tax information </h5>
					<ul className="">
						<li className={`${styles.footer__item}`}>
							<Link to="/tax-type">Tax Types</Link>
						</li>
						<li className={`${styles.footer__item}`}>
							<Link to="/tax-system">Tax Systems</Link>
						</li>
						<li className={`${styles.footer__item} `}>
							<Link to="/tax-collection">Tax Collection</Link>
						</li>
						<li className={`${styles.footer__item} `}>
							<Link to="/tax-legislation">Tax Legislation </Link>
						</li>
					</ul>
				</div>

				<div className="">
					<h5>MORE</h5>
					<ul className="">
						<li className={`${styles.footer__item} `}>
							<Link to="/about-us">About Us</Link>
						</li>
						<li
							className={`${styles.footer__item} `}
							type="button"
							data-toggle="modal"
							data-target="#lookUpESBN"
						>
							Confirm ESBN
						</li>
						<li className={`${styles.footer__item}`}>
							<Link to="/frequently-asked-questions">
								Visit our FAQ
							</Link>
						</li>
					</ul>
				</div>

				<div className="">
					<h5>PAY TAX WITH</h5>
					<ul className="">
						{constants.paymentGateways.map((gateway) => (
							<li
								key={gateway.link}
								className={`${styles.footer__item} `}
							>
								<Link to={gateway.link}>{gateway.name}</Link>
							</li>
						))}
					</ul>
				</div>
				<div className="">
					<h5>FOLLOW US ON</h5>
					<ul className="">
						<li className={`${styles.socialMedia} `}>
							<a
								href="https://www.facebook.com/profile.php?id=61559661552725&mibextid=ZbWKwL"
								target="_blank"
								rel="noreferrer"
							>
								<img src={Facebook} alt="Facebook" />
							</a>
							<a
								href="https://www.instagram.com/esrs_norlics?igsh=MXBqeHBqaWpsNmZlbg=="
								target="_blank"
								rel="noreferrer"
							>
								<img src={Instagram} alt="Instagram" />
							</a>
							<a
								href="https://x.com/norlics20125?t=kDI_rEmAwK-amtHJogWgCA&s=09"
								target="_blank"
								rel="noreferrer"
							>
								<img src={X} alt="Facebook" />
							</a>
						</li>
					</ul>
				</div>
			</div>
			<hr style={{ width: "85%", margin: "auto" }} />
			<div className={styles.credits}>
				<p className="col-6">
					&copy; Copyright {new Date().getFullYear()} ESIRS. All
					rights reserved
				</p>
				<p className="col-6">
					Powered by &nbsp;
					<img src={Norlics} alt="Norlics" />
				</p>
			</div>
		</>
	);
};

export default Footer;
