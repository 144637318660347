import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import GoHomeIcon from "../../assets/goToLinkIcon.svg";
import Logo from "../../assets/logo.svg";
import Alert from "../../components/Alert";
import Aside from "../../components/Aside";
import styles from "./style.module.css";

const Login = () => {
	const [values, setValue] = useState({
		email: "",
		password: ""
	});
	const [fetching, setFetching] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	function handleChange(e) {
		const name = e.target.name;
		const value = e.target.value;

		setValue({
			...values,
			[name]: value
		});
	}

	const signIn = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const res = await axios
				.post(`/auth/login`, {
					...values
				})
				.then((res) => res.data);
			if (!res.data) throw new Error("Invalid Login Details.");
			if (res.data.status === "FAIL") throw new Error(res.data.msg);

			localStorage.setItem("access_token", res.data.access_token);
			localStorage.setItem(
				"user_info",
				JSON.stringify({ ...res.data, access_token: undefined })
			);

			window.location.href = "/";
		} catch (err) {
			if (/401/.test(err.message)) {
				window.location.href = "/otp-phone";
			}
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: /404/.test(err.message)
					? "Invalid Login Details"
					: err.response?.data.message || err.message
			});
		} finally {
			setFetching(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside showOnMobile={false} />
			<main className={styles.auth__form__container}>
				<Link to="/home">
					Go back to Home <img src={GoHomeIcon} alt="go home icon" />
				</Link>
				<img src={Logo} alt="logo" />
				<h1 className={styles.heading__text}>Log In</h1>
				<p className={styles.sub__heading__text}>
					Using the same details at Sign Up, kindly login with either
					your phone number or your email address.
					<br /> Don't have an account?{" "}
					<Link to="/signup">Sign up</Link>
				</p>
				<form className={styles.auth__form} onSubmit={signIn}>
					<label htmlFor="phone-email">Email Address/ESBN</label>
					<input
						id="phone-email"
						type="text"
						name="email"
						value={values.email}
						onChange={handleChange}
						placeholder="ESBN for corporate account, email for individual"
						required
					/>
					<span style={{ fontSize: "11px", marginBottom: "16px" }}>
						Use ESBN for corporate account, email for individual.
					</span>
					<label htmlFor="password">Password</label>
					<input
						id="password"
						type="password"
						name="password"
						value={values.password}
						onChange={handleChange}
						placeholder="**********"
					/>
					<Link to="/reset-password">Forgot password?</Link>
					<button
						type="submit"
						className={`${styles.submit__button} primary__btn`}
						disabled={
							!values.email ||
							// || !values.password
							fetching
						}
					>
						{fetching ? "Logging In..." : "Log In"}
					</button>
				</form>
			</main>
		</section>
	);
};

export default Login;
