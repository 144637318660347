import ETranzactLogo from "../src/assets/Home/etransactLogo.png";
import FlutterwaveLogo from "../src/assets/Home/flutterwaveLogo.webp";
import MoniepointLogo from "../src/assets/Home/moniepointLogo.svg";
import RemitaLogo from "../src/assets/Home/remitaLogo.png";

const constants = {
	BASE_URL: "https://beta.norlics.ng/api",
	BASE1_URL: "https://beta.norlics.ng/api",
	BASE_URL_2: "https://beta.norlics.ng/api",
	BASE_URL_3: "https://beta.norlics.ng/api",
	paymentGateways: [
		{
			name: "Flutterwave",
			link: "/pay/flutterwave",
			img: FlutterwaveLogo
		},
		{
			name: "Remita",
			link: "/pay/remita",
			img: RemitaLogo
		},
		{
			name: "E-tranzact",
			link: "/pay/etranzact",
			img: ETranzactLogo
		},
		{
			name: "Moniepoint",
			link: "/pay/moniepoint",
			img: MoniepointLogo
		}
	]
};

export default constants;
