import { useHistory } from "react-router-dom";

import DoneFileIcon from "../../assets/donefile.svg";
import styles from "../../Identity-management-corporate/final.module.css";

const OTPSuccess = ({ message, accountType }) => {
	const history = useHistory();

	return (
		<div className={styles.success}>
			<img src={DoneFileIcon} alt="done file" />
			<h1 className={styles.finito}>Success!</h1>
			<p className={styles.message}>{message}</p>
			<button
				className="primary__btn"
				onClick={
					accountType === "individual"
						? () => history.push("/login")
						: () =>
								(window.location.href =
									"/corporate-identity-management")
				}
			>
				{accountType === "individual" ? "Login" : "Continue"}
			</button>
		</div>
	);
};

export default OTPSuccess;
