import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import mailIcon from "../assets/Home/AnnouncementPopUp/mail.icon.svg";
import phoneIcon from "../assets/Home/AnnouncementPopUp/phone.icon.svg";
import esirsLogo from "../assets/Home/ESIRSLogo.svg";
// import coatOfArm from "../assets/coatOfArm.svg";
import closeIcon from "../assets/x-circle-black.svg";
import styles from "./news.modal.module.css";

const NewsModal = () => {
	const [modalVisible, setModalVisible] = useState(true);
	const [remainingDays, setRemainingDays] = useState();
	const [remainingHours, setRemainingHours] = useState();
	const [remainingMinutes, setRemainingMinutes] = useState();
	const [remainingSeconds, setRemainingSeconds] = useState();

	// checking if the user has visited the page and has seen the news modal //
	// useEffect(() => {
	// 	if (!localStorage.getItem("modalShown")) {
	// 	setModalVisible(true);
	// 	localStorage.setItem("modalShown", "true");
	// 	}
	// }, []);

	// const downloadImage = () => {
	// 	const link = document.createElement("a");
	// 	link.href = ESIRSJTBTIN;
	// 	link.download = "ESIRS JTB TIN";
	// 	document.body.appendChild(link);
	// 	link.click();
	// 	document.body.removeChild(link);
	// };

	const steps = [
		<>
			Login to <Link to="/login">www.irs.en.gov.ng</Link> using your
			organisation's ESBN and password
		</>,
		<>
			Click <b>"E-Filing"</b> and select <b>“PAYE Filing”</b>.
		</>,
		<>
			Click on <b>"File Returns"</b> and choose your filing frequency
			(Yearly, Monthly, or Period).
		</>,
		<>Specify the filing period (Year, Month, or custom Period).</>,
		<>
			Download the template, fill it out correctly, upload it, and click
			Submit.
		</>,
		<>Enter filer details and upload a signature.</>,
		<>Include payment receipt numbers for the filing year.</>,
		<>Upload receipts and click "FILE RETURNS".</>,
		<>
			Preview details, check the box for final submission, and generate
			the H1 Form.
		</>,
		<>Note and make payments for any negative variances.</>,
		<>Wait for final approval by the EIRS.</>
	];

	function countdown() {
		const deadline = new Date("2025-01-31T23:59:59Z").getTime();
		const now = new Date().getTime();
		const timeLeft = deadline - now;

		if (timeLeft < 0) {
			setRemainingDays(0);
			setRemainingHours(0);
			setRemainingMinutes(0);
			setRemainingSeconds(0);
			return;
		}

		const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

		setRemainingDays(days);
		setRemainingHours(hours);
		setRemainingMinutes(minutes);
		setRemainingSeconds(seconds);
	}

	useEffect(() => {
		countdown();
	}, []);

	useEffect(() => {
		const interval = setInterval(countdown, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{modalVisible && (
				<>
					<div
						className={`${styles.modalOverlay} ${
							modalVisible ? styles.visible : styles.invisible
						}`}
						onClick={() => setModalVisible(false)}
					></div>
					<div
						className={styles.modalContent}
						onClick={(e) => e.stopPropagation()}
						aria-label="announcement modal"
					>
						<div
							aria-label="announcement modal header"
							className={styles.announcement__header}
						>
							<button
								className={styles.close__btn}
								onClick={() => setModalVisible(false)}
							>
								<img
									src={closeIcon}
									alt="modal close button icon"
								/>
							</button>
							<div className={styles.announcement__description}>
								<div>
									<h2>Important Tax Filing Reminder!</h2>
									<p>
										Organizations are required by law to
										file tax returns for employee emoluments
										paid between January - December 2024.
										Ensure you file and pay taxes promptly
										to avoid penalties.
									</p>
								</div>
								<div className={styles.deadline}>
									<span>FILE ON OR BEFORE</span>
									<span>JAN 31ST, 2025</span>
								</div>
							</div>
						</div>
						<div
							aria-label="announcement modal body"
							className={styles.announcement__body}
						>
							<h3>Steps to File for Returns</h3>
							<ul className={styles.steps}>
								{steps.map((step, index) => (
									<li key={index}>
										<span>{index + 1}</span>
										<span>{step}</span>
									</li>
								))}
							</ul>
							<div className={styles.deadline}>
								<span>FILE ON OR BEFORE</span>
								<span>JAN 31ST, 2025</span>
							</div>
							<p>
								Organizations are mandated in line with the
								extant provisions of the Personal Income Tax Act
								2004 (as amended) to file the returns on all
								emoluments paid to their employees from Jan -
								Dec 2024 and ensure that due taxes are paid
								immediately!!!!!
							</p>
						</div>
						<div
							aria-label="announcement modal footer"
							className={styles.announcement__footer}
						>
							<div className={styles.countdown__container}>
								<p>
									2025 COMPANY ANNUAL TAX RETURNS FILING ENDS
									IN
								</p>
								<div className={styles.countdown}>
									<div>
										<span>DAYS</span>
										<span>{remainingDays}</span>
									</div>
									<div>
										<span>HOURS</span>
										<span>{remainingHours}</span>
									</div>
									<div>
										<span>MINUTES</span>
										<span>{remainingMinutes}</span>
									</div>
									<div>
										<span>SECONDS</span>
										<span>{remainingSeconds}</span>
									</div>
								</div>
							</div>
							<div className={styles.contact__container}>
								<div>
									<b>For more Information</b>
									<address>
										<a href="mailto:esirs@enugustate.gov.ng">
											<img
												src={mailIcon}
												alt="mail icon"
											/>
											esirs@enugustate.gov.ng
										</a>
										<a href="tel:+2348075625555">
											<img
												src={phoneIcon}
												alt="phone icon"
											/>
											+234 807 562 5555
										</a>
									</address>
								</div>
								<div>
									<span>
										Enugu State
										<br />{" "}
										<span>Internal Revenue Service</span>
									</span>
									<img
										src={esirsLogo}
										alt="Enugu State Internal Revenue Service Logo"
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default NewsModal;
