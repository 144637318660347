import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import somethingWentWrongImg from "../../assets/SomethingWentWrong/somethingWentWrong.svg";
import styles from "./style.module.css";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	render() {
		const { history } = this.props;

		if (this.state.hasError) {
			return (
				<section className={styles.section}>
					<img
						src={somethingWentWrongImg}
						alt="laptop with a danger sign"
					/>
					<h2>SOMETHING WENT WRONG</h2>
					{process.env.NODE_ENV === "development" ? (
						<p>Please check the console for more information.</p>
					) : (
						<p>
							Please try again later. If the problem persists,
							please contact the support team at{" "}
							<a href="mailto:esirs@enugustate.gov.ng">
								esirs@enugustate.gov.ng
							</a>
						</p>
					)}
					<button
						className="primary__btn"
						onClick={() => history.goBack()}
						type="button"
					>
						Go Back
					</button>
				</section>
			);
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
