import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Success from "../assets/Signup/success.svg";
import Alert from "../components/Alert";
import Aside from "../components/Aside";
import styles from "../Identity-management-corporate/final.module.css";

const IndividualESBNRegSucess = () => {
	const location = useLocation();
	const ESBN = location.state;
	const history = useHistory();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [eCardMode, setECardMode] = useState(null);
	const [modesShowing, setModesShowing] = useState(false);
	const [generatingCard, setGeneratingCard] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const getECard = async () => {
			setGeneratingCard(true);
			try {
				const info = await axios
					.get(
						`/admin/individual-taxpayer/system/generateesbncard/${ESBN}/${eCardMode}`,
						{
							responseType: eCardMode === 2 ? "blob" : "json"
						}
					)
					.then((res) => res.data);

				if (eCardMode === 2) {
					const url = window.URL.createObjectURL(info);
					const link = document.createElement("a");
					link.href = url;
					link.download = "ESBN-e-card.pdf"; // specify the desired file name
					document.body.appendChild(link);
					link.click();

					// Clean up
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				}

				if (eCardMode === 1) {
					const w = window.open();
					w.document.write(info);
					w.document.close();
					w.onload = () => {
						w.focus();
						w.print();
					};

					w.onafterprint = () => {
						w.close();
					};
				}

				if (eCardMode === 3) {
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message: info.msg
					});
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setGeneratingCard(false);
				setECardMode(null);
			}
		};

		if (eCardMode) getECard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eCardMode]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<main className={styles.success__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside showOnMobile={false} />
			<div className={styles.success}>
				<img
					src={Success}
					alt="hand holding a championship cup with some stars around"
				/>
				<h1>Successful</h1>
				<p>
					your ESBN is <b>{ESBN}</b> and has been sent to phone number
					and email
				</p>
				<div className={styles.btn__area}>
					<button
						className="primary__btn"
						onClick={() => {
							userInfo?.account_type === "individual"
								? (window.location.href = "/")
								: history.push("/statetin/individual");
						}}
					>
						{userInfo?.account_type === "individual"
							? "Go to Dashboard"
							: "Go to Individual ESBNs"}
					</button>
					{userInfo?.account_type !== "individual" && (
						<div className={styles.get__ecard_area}>
							<button
								className="secondary__btn"
								onClick={() => setModesShowing(!modesShowing)}
							>
								{generatingCard
									? "Generating e-card..."
									: "Get e-card"}
							</button>
							{modesShowing && (
								<div className={styles.modes}>
									<button
										onClick={() => {
											setECardMode(1);
											setModesShowing(false);
										}}
									>
										Print
									</button>
									<button
										onClick={() => {
											setECardMode(2);
											setModesShowing(false);
										}}
									>
										Download as PDF
									</button>
									<button
										onClick={() => {
											setECardMode(3);
											setModesShowing(false);
										}}
									>
										Send as email
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</main>
	);
};

export default IndividualESBNRegSucess;
