import axios from "axios";
import { useEffect, useState } from "react";

import findESBNimage from "../../assets/Home/findESBNimage.svg";
import constants from "../../constants";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
import style from "./style.module.css";

const FindESBN = () => {
	useIntersectionObserver();
	const [surname, setSurname] = useState("");
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [corpName, setCorpName] = useState("");
	const [corpEmail, setCorpEmail] = useState("");
	const [fetchedESBN, setFetchedESBN] = useState(null);
	const [searchFor, setSearchingFor] = useState("individual");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleGetESBN = async () => {
		setLoading(true);
		setFetchedESBN("");
		setErrorMessage("");
		try {
			let response;
			if (searchFor === "individual") {
				response = await axios.post(
					`${constants.BASE_URL_2}/search/esbn`,
					{
						surName: surname,
						firstName,
						email,
						phoneNumber
					}
				);
			} else if (searchFor === "corporate") {
				response = await axios.post(
					`${constants.BASE_URL_2}/search/esbn/corporate`,
					{ name: corpName, email: corpEmail }
				);
			}

			if (response?.data?.data?.esbn) {
				setFetchedESBN(response.data.data.esbn);
			} else {
				setErrorMessage("ESBN not found");
			}
		} catch (err) {
			setErrorMessage(
				err?.response?.data?.message ?? "Something went wrong"
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setErrorMessage("");
		}, 3000);

		return () => clearTimeout(timer);
	});

	return (
		<section className={`${style.esbnSection} `} id="find-ESBN">
			<img
				src={findESBNimage}
				alt="Find ESBN"
				className={`${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
			/>
			<div
				className={`${style.searchSection} ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
			>
				<h2>
					Find <span className={style.underlineGreen}>ESBN</span>
				</h2>
				<h3>Swiftly locate and verify your ESBN number.</h3>
				<form className={style.radioBtns}>
					<div className={style.radio}>
						<input
							id="individualRadio"
							type="radio"
							checked={searchFor === "individual"}
							onChange={() => {
								setSearchingFor("individual");
								setSurname("");
								setFirstName("");
								setEmail("");
								setPhoneNumber("");
								setFetchedESBN("");
								setErrorMessage("");
							}}
						/>
						<label htmlFor="individualRadio">For Individual</label>
					</div>
					<div className={style.radio}>
						<input
							id="corporateRadio"
							type="radio"
							checked={searchFor === "corporate"}
							onChange={() => {
								setSearchingFor("corporate");
								setCorpName("");
								setCorpEmail("");
								setFetchedESBN("");
								setErrorMessage("");
							}}
						/>
						<label htmlFor="corporateRadio">For Organization</label>
					</div>
				</form>
				{searchFor === "individual" && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleGetESBN();
						}}
						className={style.inputField}
					>
						<div className={style.individualInput}>
							<input
								placeholder="Surname"
								type="text"
								value={surname}
								onChange={(e) => setSurname(e.target.value)}
							/>

							<input
								placeholder="First Name"
								type="text"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
						<div className={style.individualInput}>
							<input
								placeholder="Email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>

							<input
								placeholder="Phone No"
								type="tel"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
						</div>
						<button
							type="submit"
							className={style.searchBtn}
							disabled={
								!surname || !firstName || !email || !phoneNumber
							}
							style={{
								backgroundColor:
									surname && firstName && email && phoneNumber
										? "#34aa63"
										: ""
							}}
						>
							{loading ? "Finding ESBN..." : "Find ESBN"}
						</button>

						<p className={style.result}>
							{fetchedESBN ? `Your ESBN is: ${fetchedESBN}` : ""}
						</p>
						<p style={{ color: "red", textAlign: "center" }}>
							{errorMessage ? errorMessage : ""}
						</p>
					</form>
				)}
				{searchFor === "corporate" && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleGetESBN();
						}}
						className={style.corporateInput}
					>
						<input
							placeholder="Company Name"
							type="text"
							value={corpName}
							onChange={(e) => setCorpName(e.target.value)}
						/>
						<br />
						<input
							placeholder="Company email"
							type="email"
							value={corpEmail}
							onChange={(e) => setCorpEmail(e.target.value)}
						/>

						<button
							type="submit"
							className={style.searchBtn}
							disabled={!corpName || !corpEmail}
							style={{
								backgroundColor:
									corpName && corpEmail ? "#34aa63" : ""
							}}
						>
							{loading ? "Loading..." : "Find ESBN"}
						</button>
						<p className={style.result}>
							{fetchedESBN
								? `Your Organization ESBN is: ${fetchedESBN}`
								: ""}
						</p>
						<p style={{ color: "red", textAlign: "center" }}>
							{errorMessage ? errorMessage : ""}
						</p>
					</form>
				)}
			</div>
		</section>
	);
};

export default FindESBN;
