import { lazy, Suspense } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from "react-router-dom";

import Login from "./Auth/Login/Login";
import OTP from "./Auth/OTP";
import ResetPassword from "./Auth/ResetPassword";
import SignUp from "./Auth/SignUp/signup";
import ErrorBoundary from "./components/ErrorBoundary";
import PageNotFound from "./components/PageNotFound";
import Spinner from "./components/spinner";
import Home from "./Home";
import AboutUs from "./Home/about-us";
import FaqPage from "./Home/faqPage";
import CorporateIdentityManagement from "./Identity-management-corporate";
import CorporateIdentityManagementSuccess from "./Identity-management-corporate/final";
import IndividualIdentityManagement from "./Identity-management-individual";
import IndividualIdentityManagementSuccess from "./Identity-management-individual/final";
import ETranzact from "./payment/etranzact";
import Flutterwave from "./payment/flutterwave";
import Interswitch from "./payment/interswitchpay";
import MoniePoint from "./payment/moniepoint";
import RemitaCheckout from "./payment/remita";
import Unifiedpayment from "./payment/unifiedpayment";

const Dashboard = lazy(() => import("./Dashboard"));
const TaxType = lazy(() => import("./Home/tax-type"));
const TaxSystem = lazy(() => import("./Home/tax-system"));
const TaxCollection = lazy(() => import("./Home/tax-collection"));
const TaxLegislation = lazy(() => import("./Home/tax-legislation"));
const ConsolidatedSteps = lazy(() => import("./Home/consolidated-steps"));
const NewsPage = lazy(() => import("./Home/newsPage"));
const TCCVerification = lazy(() => import("./TCC-Verification"));

function App() {
	const accessToken = localStorage.getItem("access_token");
	let userInfo = localStorage.getItem("user_info");
	if (userInfo) {
		userInfo = JSON.parse(userInfo);
	}

	return (
		<Router>
			<Switch>
				<Route path="/home">
					<Home />
				</Route>
				<Route path="/about-us">
					<AboutUs />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/signup">
					<SignUp />
				</Route>
				<Route path="/otp-phone">
					<OTP title="Phone" />
				</Route>
				<Route path="/otp-email">
					<OTP title="Email" />
				</Route>
				<Route path="/frequently-asked-questions">
					<FaqPage />
				</Route>
				<Route path="/identity-management">
					{userInfo?.account_type === "corporate" ? (
						<CorporateIdentityManagement />
					) : (
						<IndividualIdentityManagement />
					)}
				</Route>
				<Route path="/individual-identity-management">
					{!/^(individual)|(corporate)$/i.test(
						userInfo?.account_type
					) ? (
						<IndividualIdentityManagement />
					) : (
						<div>You should not be here...</div>
					)}
				</Route>
				<Route path="/corporate-identity-management">
					<CorporateIdentityManagement />
				</Route>
				<Route path="/CIM-success">
					<CorporateIdentityManagementSuccess />
				</Route>
				<Route path="/IM-success">
					<IndividualIdentityManagementSuccess />
				</Route>
				<Route path="/pay/unifiedpayment">
					<Unifiedpayment />
				</Route>
				<Route path="/pay/flutterwave">
					<Flutterwave />
				</Route>
				<Route path="/pay/interswitch">
					<Interswitch />
				</Route>
				<Route path="/pay/moniepoint">
					<MoniePoint />
				</Route>
				<Route path="/pay/remita">
					<RemitaCheckout />
				</Route>
				<Route path="/pay/etranzact">
					<ETranzact />
				</Route>
				<Route path="/404">
					<PageNotFound />
				</Route>
				{/* lazy-loaded pages begin */}
				<ErrorBoundary>
					<Suspense fallback={<Spinner />}>
						<Route path="/latest-news">
							<NewsPage />
						</Route>
						<Route path="/tax-type">
							<TaxType />
						</Route>
						<Route path="/tax-system">
							<TaxSystem />
						</Route>
						<Route path="/tax-collection">
							<TaxCollection />
						</Route>
						<Route path="/tax-legislation">
							<TaxLegislation />
						</Route>
						<Route path="/steps-to-pay-bills">
							<ConsolidatedSteps />
						</Route>
						<Route path="/enugutccverification">
							<TCCVerification />
						</Route>
						<Route path="/">
							{(userInfo?.account_type &&
								!/^(individual)|(corporate)$/i.test(
									userInfo?.account_type
								)) ||
							userInfo?.anssid ? (
								<Dashboard />
							) : accessToken ? (
								<Redirect to="/identity-management" />
							) : (
								<Redirect to="/404" />
							)}
						</Route>
					</Suspense>
				</ErrorBoundary>
			</Switch>
		</Router>
	);
}

export default App;
