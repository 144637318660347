import { useEffect } from "react";

import AliceMbah from "../../assets/Home/AliceMbah.svg";
import AnthonyUgwuanyi from "../../assets/Home/AnthonyUgwuanyi.svg";
import ArinzeNnamani from "../../assets/Home/ArinzeNnamani.svg";
import ChikwadoMbalagu from "../../assets/Home/ChikwadoMbalagu.svg";
import ChinasaEdeh from "../../assets/Home/ChinasaEdeh.svg";
import ChiomaOnyia from "../../assets/Home/ChiomaOnyia.svg";
import ChristianNnaji from "../../assets/Home/ChristianNnaji.svg";
import coreValueImg from "../../assets/Home/coreValueImg.svg";
import CynthiaNnam from "../../assets/Home/CynthiaNnam.svg";
import CynthiaUgwu from "../../assets/Home/CynthiaUgwu.svg";
import DennisNnamani from "../../assets/Home/DennisNnamani.svg";
import executiveChairman from "../../assets/Home/executiveChairman.svg";
import FelixNwatu from "../../assets/Home/FelixNwatu.svg";
import GinikaEzea from "../../assets/Home/GinikaEzea.svg";
import HenriettaAmadi from "../../assets/Home/HenriettaAmadi.svg";
import JosephUgwu from "../../assets/Home/JosephUgwu.svg";
import LeticiaNnamani from "../../assets/Home/LeticiaNnamani.svg";
import MargaretEze from "../../assets/Home/MargaretEze.svg";
import NgoziEzema from "../../assets/Home/NgoziEzema.svg";
import NgoziNdukwe from "../../assets/Home/NgoziNdukwe.svg";
import NkechiAbiah from "../../assets/Home/NkechiAbiah.svg";
import NnamdiEneh from "../../assets/Home/NnamdiEneh.svg";
import NwabuezeChukwude from "../../assets/Home/NwabuezeChukwude.svg";
import NwadiutoOkereke from "../../assets/Home/NwadiutoOkereke.svg";
import OnuoraOkeke from "../../assets/Home/OnuoraOkeke.svg";
import SimonNjoku from "../../assets/Home/SimonNjoku.svg";
import VisionMissionImg from "../../assets/Home/VisionMissionImg.svg";
import whatWeDoImg from "../../assets/Home/whatWeDoImg.svg";
import Subscription from "../../Home/subscription/index";
import FlexRight from "../FlexRight-template";
import Footer from "../footer";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
import NavBar from "../nav-bar";
import style from "./style.module.css";

const higherExecutive = [
	{
		id: 2,
		image: FelixNwatu,
		name: "Felix Nwatu ",
		position: "Director Finance & Account",
		alt: "Felix Nwatu"
	},
	{
		id: 3,
		image: DennisNnamani,
		name: "Dennis Nnamani ",
		position: "Director Land Use Charge",
		alt: "Dennis Nnamani"
	},
	{
		id: 4,
		image: CynthiaNnam,
		name: "Cynthia Nnam ",
		position: "Head ICT",
		alt: "Cynthia Nnam"
	},
	{
		id: 5,
		image: ChristianNnaji,
		name: "Christian Nnaji ",
		position: "Director PRS",
		alt: "Christian Nnaji"
	}
];

const staffs = [
	{
		id: 1,
		image: NwabuezeChukwude,
		name: "Nwabueze Chukwude ",
		position: "Director Administration",
		alt: " Nwabueze Chukwude"
	},
	{
		id: 2,
		image: AnthonyUgwuanyi,
		name: "Anthony Ugwuanyi ",
		position: "Coordinator Area Authorities",
		alt: "Anthony Ugwuanyi"
	},
	{
		id: 3,
		image: OnuoraOkeke,
		name: "Onuora Okeke ",
		position: "Head Professional Practices",
		alt: "Onuora Okeke"
	},
	{
		id: 4,
		image: MargaretEze,
		name: "Margaret Eze ",
		position: "Director Collections",
		alt: "Margaret Eze"
	},
	{
		id: 5,
		image: JosephUgwu,
		name: "Joseph Ugwu ",
		position: "Director Motor Vehicle Administration",
		alt: " JosephUgwu"
	},
	{
		id: 6,
		image: SimonNjoku,
		name: "Simon Njoku ",
		position: "Adviser on Tax to the Chairman",
		alt: "Simon Njoku"
	},

	{
		id: 7,
		image: AliceMbah,
		name: "Alice Mbah ",
		position: "Director Back Duty Audit and Investigation",
		alt: "Alice Mbah"
	},

	{
		id: 8,
		image: NkechiAbiah,
		name: "Nkechi Abiah ",
		position: "Head HNI Unit",
		alt: "Nkechi Abiah"
	},
	{
		id: 9,
		image: ChikwadoMbalagu,
		name: "Chikwado Mbalagu ",
		position: "Director Informal Sector",
		alt: "Chikwado Mbalagu"
	},
	{
		id: 10,
		image: NwadiutoOkereke,
		name: "Nwadiuto Okereke ",
		position: "Director Caputal Gains Tax and Stamp Duties",
		alt: "Nwadiuto Okereke"
	},
	{
		id: 11,
		image: CynthiaUgwu,
		name: "Cynthia Ugwu ",
		position: "Director Enforcement and Monitoring",
		alt: "Cynthia Ugwu"
	},
	{
		id: 12,
		image: ArinzeNnamani,
		name: "Arinze Nnamani ",
		position: "Technical Adviser to the Chairman",
		alt: "Arinze Nnamani"
	},
	{
		id: 13,
		image: GinikaEzea,
		name: "Ginika Ezea ",
		position: "Head Purchase Tax Unit",
		alt: "Ginika Ezea"
	},
	{
		id: 14,
		image: HenriettaAmadi,
		name: "Henrietta Amadi ",
		position: "Director Assessment",
		alt: "Henrietta Amadi"
	},
	{
		id: 15,
		image: LeticiaNnamani,
		name: "Leticia Nnamani ",
		position: "Head Internal Audit",
		alt: "Leticia Nnamani"
	},
	{
		id: 16,
		image: NgoziEzema,
		name: "Ngozi Ezema ",
		position: "Head Legal Department",
		alt: "Ngozi Ezema"
	},
	{
		id: 17,
		image: NnamdiEneh,
		name: "Nnamdi Eneh ",
		position: "PRO",
		alt: "Nnamdi Eneh"
	},
	{
		id: 18,
		image: NgoziNdukwe,
		name: "Ngozi Ndukwe ",
		position: "NGF & JTB Desk Officer",
		alt: "Ngozi Ndukwe"
	},
	{
		id: 19,
		image: ChiomaOnyia,
		name: "Chioma Onyia ",
		position: "Secretary to the Chairman",
		alt: "Chioma Onyia"
	},
	{
		id: 20,
		image: ChinasaEdeh,
		name: "Chinasa Edeh ",
		position: "Admin Assistant",
		alt: "Chinasa Edeh"
	}
];

const coreValues = [
	{
		Respect:
			"Respect to authority, respect to taxpayers, respect to colleagues."
	},
	{
		Innovation:
			"Apply technology and rejig model to meet the environment need."
	},
	{
		Professionalism:
			"Behavior in line with work ethics, professional ethics and business ethics."
	},
	{
		Efficiency:
			" Operation leads to quicker and revenue increases on a daily basis."
	}
];

const AboutUs = () => {
	useIntersectionObserver();
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<h1>About Us </h1>
				<h3>What you need to know About EIRS</h3>
			</section>
			<FlexRight
				headingIn={
					<span>
						<span className={style.underlineGreen}>What We</span>{" "}
						<span>Do</span>
					</span>
				}
				content2="Enugu State Government has been making concerted and vigorous efforts at increasing and improving their IGR requiring the cooperative efforts of all the loving citizens and organizations in the state. As the State moves towards the realization of becoming a mega city, EIRS continues to position itself as a major funding arm of the state government by putting in place structures that will ensure collection of the proper amount of revenue at the least cost while also working with MDAs to move in the same direction to realize the visions of Enugu State."
				content3="EIRS is the major funding arm of the Enugu State Government charged with the responsibility of collecting taxes and other revenues. Over the years, EIRS has increased the State’s Internally Generated Revenue (IGR) by executing various pioneering programs and implementing strategies which have positively impacted revenue generation and collection."
				image={whatWeDoImg}
			/>

			<section className={style.VisionMission}>
				<img
					src={VisionMissionImg}
					alt="taxTypeImg"
					className={`${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
				/>
				<div
					className={`${style.paragraphs} ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
				>
					<h1>
						<span className={style.underlineGold}>Vision</span>{" "}
						Statement
					</h1>
					<p>
						We aim to be a sustainable Revenue Service driven by
						innovation for the development of a new Enugu State.
					</p>
					<h1>
						<span className={style.underlineGold}>Mission</span>{" "}
						Statement{" "}
					</h1>
					<p>
						We are about efficient and taxpayer-focused State
						Revenue Service driving and facilitating collection
						for Enugu State.
					</p>
				</div>
			</section>
			<section className={style.coreValueSection}>
				<img
					src={coreValueImg}
					alt="taxTypeImg"
					className={` ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
				/>
				<div
					className={`${style.CoreValues} ${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
				>
					<h1 className={style.subHeading}>
						<span className={style.underlineGreen}>Core Va</span>lue
					</h1>
					{coreValues.map((value) => (
						<div key={value.id} className={style.values}>
							{Object.entries(value).map(([key, val]) => (
								<>
									<strong>
										<span className={style.bullet}>.</span>
										{key}
									</strong>
									<p>{val}</p>
								</>
							))}
						</div>
					))}
					<div className={style.RIPE}>
						<p>R</p>
						<p>I</p>
						<p>P</p>
						<p>E</p>
					</div>
				</div>
			</section>
			<section className={style.management}>
				<h2>
					Mana<span className={style.underlineGreen}>gement</span>{" "}
					Staff
				</h2>
				<img
					src={executiveChairman}
					alt="executive Chairman"
					className={style.executiveChairman}
				/>
				<h3>Emmanuel Ekene Nnamani</h3>
				<p>Executive Chairman</p>
				<div className={style.higherStaffs}>
					{higherExecutive.map((higher) => (
						<div key={higher.id} className={style.higherStaffCard}>
							<img
								src={higher.image}
								alt={higher.alt}
								className={style.staffCard}
							/>
							<h3>{higher.name}</h3>
							<p>{higher.position}</p>
						</div>
					))}
				</div>
				<div className={style.staffs}>
					{staffs.map((staff) => (
						<div key={staff.id} className={style.staffCard}>
							<img
								src={staff.image}
								alt={staff.alt}
								className={style.staffCard}
								loading="lazy"
							/>
							<h3>{staff.name}</h3>
							<p>{staff.position}</p>
						</div>
					))}
				</div>
			</section>
			<Subscription />
			<Footer />
		</main>
	);
};

export default AboutUs;
