import axios from "axios";
import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/Home/closeIcon.svg";
import styles from "../style.module.css";
// import Alert from '../../../components/Alert';

const LookUpESBN = () => {
	const [showResult, setShowResult] = useState(false);
	const [loading, setLoading] = useState(false);
	const [esbn, setESBN] = useState("");
	const [data, setData] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const handleGetESBN = async (e) => {
		e.preventDefault();
		if (!!esbn && !isNaN(esbn)) {
			try {
				setLoading(true);
				const esbnDeets = await axios
					.get(`users/shortanssidverticals?anssid=${esbn}`)
					.then((res) => (res.data || {}).data);
				if (esbnDeets) {
					setData(esbnDeets);
					setShowResult(true);
					setLoading(false);
					setAlert({
						showing: true,
						type: "success",
						message: "Taxpayer Information found"
					});
				}
			} catch (err) {
				setLoading(false);
				setAlert({
					showing: true,
					type: "error",
					message:
						err?.response?.data?.msg ??
						"Taxpayer Information lookup not successful."
				});
			}
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{/* {alert.showing && <Alert text={alert.message} type={alert.type} />} */}
			<div className="container demo">
				<div
					className="modal right fade"
					id="lookUpESBN"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="LookUpESBN"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className={`modal-body ${styles.modal__body}`}>
								<div>
									<CloseIcon
										type="button"
										data-dismiss="modal"
										style={{ cursor: "pointer" }}
									/>
									<h3>Confirm ESBN</h3>
								</div>
								{!showResult ? (
									<>
										<label htmlFor="esbnField">
											ESBN
											<span className="text-danger">
												*
											</span>
										</label>
										<div className="input-group">
											<span
												className="input-group-addon"
												id="sizing-addon1"
											></span>
											<input
												type="text"
												id="esbnField"
												className="form-control"
												placeholder="Please, enter your ESBN?"
												aria-describedby="sizing-addon2"
												value={esbn}
												onChange={(e) =>
													setESBN(e.target.value)
												}
											/>
										</div>

										<button
											className={`btn btn-success ${styles.my_30}`}
											type="button"
											disabled={!esbn}
											onClick={handleGetESBN}
										>
											{loading
												? "Loading..."
												: "Get ESBN"}
										</button>

										{alert.showing && (
											<div
												className={
													alert.type === "success"
														? [
																styles.sidebar__alert,
																styles.success
															].join(" ")
														: alert.type === "error"
															? [
																	styles.sidebar__alert,
																	styles.error
																].join(" ")
															: styles.sidebar__alert
												}
											>
												{alert.message}
											</div>
										)}
									</>
								) : (
									<>
										<p>
											Instantly get and verify your ESBN
											details.
										</p>

										<br />
										<h4>ESBN Details</h4>

										<br />
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>ENSSID</th>
													<th>
														{data
															? data?.anssid
															: "N/A"}
													</th>
												</tr>
												<tr>
													<th>Name</th>
													<th>
														{data
															? data?.name
															: "N/A"}
													</th>
												</tr>
												<tr>
													<th>Email</th>
													<th>
														{data
															? data?.email
															: "N/A"}
													</th>
												</tr>
												<tr>
													<th>Phone Number</th>
													<th>
														{data
															? data?.phone
															: "N/A"}
													</th>
												</tr>
											</thead>
										</table>
										<br />
										<br />
										<div
											className={
												styles.modal__button__wrapper
											}
										>
											<button
												className={`${styles.modal__button} btn`}
												onClick={() => {
													setData(null);
													setShowResult(false);
													setAlert({
														showing: false,
														type: null,
														message: ""
													});
												}}
											>
												Check again
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LookUpESBN;
