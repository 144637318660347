import axios from "axios";
import * as faceapi from "face-api.js";
import { createRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import profileImage from "../assets/Header/userManagement.svg";
import Alert from "../components/Alert";
import Aside from "../components/Aside";
import GoBackButton from "../components/goBackButton";
import Modal from "../components/Modal/regular";
import { countries, countryKeys } from "../countries";
import styles from "./style.module.css";

function titleCase(str) {
	return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const minConfidence = 0.7;

const videoEle = createRef();
const canvasEle = createRef();
const cameraContainer = createRef();

const IndividualInformation = () => {
	const history = useHistory();
	const { state: locationState } = useLocation();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	// individual
	const [nin, setNIN] = useState(
		locationState?.view || locationState?.edit ? locationState?.NIN : ""
	);
	const [TIN, setTIN] = useState(
		locationState?.view || locationState?.edit
			? locationState?.TIN
			: userInfo?.account_type === "individual"
				? userInfo?.tin
				: ""
	);
	const [checkingTINRecord, setCheckingTINRecord] = useState(false);
	const [tinError, setTinError] = useState("");
	const [taxpayerTINRecord, setTaxpayerTINRecord] = useState(null);
	const [titles, setTitles] = useState([]);
	const [title, setTitle] = useState(
		locationState?.view || locationState?.edit ? locationState?.Title : ""
	);
	const [firstName, setFirstName] = useState(
		locationState?.view || locationState?.edit
			? locationState?.First_Name
			: userInfo?.account_type === "individual"
				? userInfo?.firstname
				: ""
	);
	const [lastName, setLastName] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Last_Name
			: userInfo?.account_type === "individual"
				? userInfo?.lastname
				: ""
	);
	const [middleName, setMiddleName] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Middle_Name
			: userInfo?.account_type === "individual"
				? userInfo?.middlename
				: ""
	);
	const [countryOfOrigin, setCountryOfOrigin] = useState(
		locationState?.view || locationState?.edit
			? locationState?.CountryOfOrigin
			: "Nigeria"
	);
	const [states, setStates] = useState([]);
	const [stateOfOrigin, setStateOfOrigin] = useState(
		locationState?.view || locationState?.edit
			? locationState?.StateOfOrigin
			: ""
	);
	const [selectedStateCode, setSelectedStateCode] = useState(null);
	const [residentialAddress, setResidentialAdress] = useState(
		locationState?.view || locationState?.edit
			? locationState?.ResidentialAddress
			: ""
	);
	const [stateOfResidence, setStateOfResidence] = useState(
		locationState?.view || locationState?.edit
			? locationState?.ResidentialState
			: "Enugu"
	);
	const [selectedStateOfResCode, setSelectedStateOfResCode] = useState(null);
	const [LGAs, setLGAs] = useState([]);
	const [LGAOfOrigin, setLGAOfOrigin] = useState(
		locationState?.view || locationState?.edit
			? locationState?.LGAOfOrigin
			: ""
	);
	const [zonesOfOrigin, setZonesOfOrigin] = useState([]);
	const [zoneOfOrigin, setZoneOfOrigin] = useState(
		locationState?.view || locationState?.edit
			? locationState?.ZoneOfOrigin
			: ""
	);
	const [LGAofResidence, setLGAofResidence] = useState(
		locationState?.view || locationState?.edit
			? locationState?.ResidentialLGA
			: ""
	);
	const [zonesOfResidence, setZonesOfResidence] = useState([]);
	const [zoneOfResidence, setZoneOfResidence] = useState(
		locationState?.view || locationState?.edit
			? locationState?.ResidentialZone
			: ""
	);
	const [phone, setPhone] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Phone_Number
			: userInfo.account_type === "individual"
				? userInfo?.phonenumber
				: ""
	);
	const [email, setEmail] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Email_Address
			: userInfo.account_type === "individual"
				? userInfo?.email
				: ""
	);
	const [gender, setGender] = useState(
		locationState?.view || locationState?.edit ? locationState?.Gender : ""
	);
	const [dateOfBirth, setDateOfBirth] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Date_Of_Birth?.slice(0, 10)
			: ""
	);
	const [occupations, setOccupations] = useState([]);
	const [occupation, setOccupation] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Occupation
			: ""
	);
	const [passport, setPassport] = useState(
		locationState?.view || locationState?.edit
			? locationState?.IdentityPhoto
			: null
	);

	const [submitting, setFetching] = useState(false);

	const [indivESBN, setIndivESBN] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Anssid
			: null
	);

	// camera states
	const initialImage =
		locationState?.view || locationState?.edit
			? locationState?.IdentityPhoto
			: profileImage;
	const [showCaptureModal, setShowCaptureModal] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	//camera work
	const showCameraContainer = () => {
		setShowCaptureModal(true);
		getUserMedia();
	};

	const hideCameraContainer = () => {
		if (window?.localStream) {
			window.localStream.getTracks().forEach((track) => track.stop());
			videoEle.current.srcObject = null;
		}
		setShowCaptureModal(false);
		clearCanvasCamera();
	};

	const clearCanvasCamera = () => {
		let canvasClear = document.getElementById("canvas1");
		let contextClear = canvasClear.getContext("2d");
		contextClear.clearRect(0, 0, canvasClear.width, canvasClear.height);
	};

	const getUserMedia = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true
			});
			videoEle.current.srcObject = mediaStream;
			window.localStream = mediaStream;
		} catch (err) {
			console.log(err);
		}
	};

	const captureImage = () => {
		try {
			// get the context object of hidden canvas
			const ctx = canvasEle.current.getContext("2d");

			let detect_mobile = window
				.getComputedStyle(
					document.getElementById("detect_mobile"),
					null
				)
				.getPropertyValue("display");
			if (detect_mobile !== "none") {
				ctx.drawImage(
					videoEle.current,
					80,
					110, //X And Y Position Of The Captured Area
					330,
					430, //Width And Height Of The Captured Area
					0,
					0, //X And Y Position Of The Captured Area To Be Displayed On The Canvas
					300,
					400 //Width And Height Of The Captured Area To Be Displayed On The Canvas
				);
			} else {
				ctx.drawImage(
					videoEle.current,
					180,
					110, //X And Y Position Of The Captured Area
					330,
					430, //Width And Height Of The Captured Area
					0,
					0, //X And Y Position Of The Captured Area To Be Displayed On The Canvas
					300,
					400 //Width And Height Of The Captured Area To Be Displayed On The Canvas
				);
			}

			// Get an image dataURL from the canvas.
			const imageDataURL = canvasEle.current.toDataURL("image/png");
			setPassport(imageDataURL);

			if (imageDataURL !== null && imageDataURL !== "") {
				//removeAttribute
				document
					.getElementById("buttonIdentityManagement")
					.removeAttribute("disabled");
			}
		} catch (error) {
			console.log("Error Message: " + error);
		}
	};

	useEffect(() => {
		faceapi.nets.ssdMobilenetv1.load("/");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (passport && passport !== initialImage) {
			updateResults();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passport]);

	async function updateResults() {
		try {
			await faceapi.nets.ssdMobilenetv1.load("/");

			const inputImgEl = document.getElementById("inputImg");
			if (!inputImgEl || !inputImgEl.getAttribute("src")) return;

			const results = await faceapi.detectAllFaces(
				inputImgEl,
				new faceapi.SsdMobilenetv1Options({ minConfidence })
			);

			const canvas = document.getElementById("overlay");
			if (!canvas) return;

			faceapi.matchDimensions(canvas, inputImgEl);
			faceapi.draw.drawDetections(
				canvas,
				faceapi.resizeResults(results, inputImgEl)
			);
		} catch (e) {
			console.error(e);
		}
	}

	const getUserInfo = async () => {
		try {
			const info = await axios
				.get(`/auth/profile`)
				.then((res) => (res.data || {}).data);

			setIndivESBN(info.anssid);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getLGAs = async () => {
		try {
			const info = await axios
				.get(`/settings/lga`)
				.then((res) => (res.data || {}).data);

			setLGAs(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getTitles = async () => {
		try {
			const info = await axios
				.get(`/settings/title`)
				.then((res) => (res.data || {}).data);
			setTitles(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getStates = async () => {
		try {
			const info = await axios
				.get(`/settings/state`)
				.then((res) => (res.data || {}).data);

			setStates(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	/**
	 * forWhat determines if you're fetching for origin or residence. 1 for origin, 2 for residence
	 * @param forWhat
	 */
	const getZones = async (LGACode, forWhat) => {
		try {
			const info = await axios
				.get(`/zone/${LGACode}`)
				.then((res) => (res.data || {}).data);

			if (forWhat === 1) setZonesOfOrigin(info);
			if (forWhat === 2) setZonesOfResidence(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getOccupations = async () => {
		try {
			const info = await axios
				.get(`/settings/occupation`)
				.then((res) => (res.data || {}).data);

			setOccupations(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	useEffect(() => {
		getStates();
		getLGAs();
		getTitles();
		getOccupations();

		getUserInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const stateCode = states.find(
			(each) => each.Description === stateOfOrigin
		)?.Code;

		setSelectedStateCode(stateCode);
	}, [stateOfOrigin, states]);

	useEffect(() => {
		const stateCode = states.find(
			(state) => state.Description === stateOfResidence
		)?.Code;

		setSelectedStateOfResCode(stateCode);
	}, [stateOfResidence, states]);

	useEffect(() => {
		const selectedLGACode = LGAs.find(
			(LGA) => LGA.Description === LGAofResidence
		)?.Code;

		getZones(selectedLGACode, 2);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [LGAofResidence, LGAs]);

	useEffect(() => {
		const selectedLGACode = LGAs.find(
			(LGA) => LGA.Description === LGAOfOrigin
		)?.Code;

		getZones(selectedLGACode, 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [LGAOfOrigin, LGAs]);

	useEffect(() => {
		if (TIN?.length >= 10) jtbChecks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [TIN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			7000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const jtbChecks = async () => {
		setCheckingTINRecord(true);
		try {
			await axios
				.post("/txn/jtb/verifyInd", {
					tin: TIN,
					userId: ""
				})
				.then((res) => {
					const {
						Taxpayer,
						ResponseCode,
						ResponseDescription,
						JTBValidatedData: {
							chkEmail,
							chkFone,
							chkFirstName,
							chkSurname,
							chkMiddleName
						}
					} = res?.data;

					if (ResponseCode === "004") {
						setTinError("TIN " + ResponseDescription);
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `TIN ${ResponseDescription}. Please, correct.`
						});
					} else if (ResponseCode === "001") {
						setTinError("");
						setTaxpayerTINRecord(Taxpayer);
						if (!locationState?.view && !locationState?.edit) {
							setFirstName(Taxpayer?.first_name);
							setMiddleName(Taxpayer?.middle_name);
							setLastName(Taxpayer?.last_name);
							setPhone(Taxpayer?.phone_no);
							setDateOfBirth(
								Taxpayer?.date_of_birth
									.split("-")
									.reverse()
									.join("-")
							);
							setEmail(Taxpayer?.email);
						}
						if (
							chkEmail !== "0" ||
							chkFone !== "0" ||
							chkFirstName !== "0" ||
							chkSurname !== "0" ||
							chkMiddleName !== "0"
						)
							setAlert({
								...alert,
								showing: true,
								type: "warning",
								message:
									"One or more fields have been modified, different from TIN records."
							});
						else
							setAlert({
								...alert,
								showing: true,
								type: "success",
								message: "TIN record found"
							});
					}
				});
		} catch (err) {
			let message = "";
			if (err.response) {
				message =
					err.response.data.errors?.details[0].message ||
					err.response.data?.error ||
					err.response.data?.errors ||
					err.response.data?.msg;
			} else {
				message = err.message;
			}
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					message ||
					"Something went wrong. Unable to submit your request."
			});
		} finally {
			setCheckingTINRecord(false);
		}
	};

	const generateESBN = async () => {
		try {
			if (indivESBN) return indivESBN;

			let query = userInfo?.id ? `userId=${userInfo.id}` : "";

			let lgaId = LGAs.find(
				(each) => each.Description === LGAOfOrigin
			).Code;

			if (countryOfOrigin !== "Nigeria") {
				lgaId = "000021";
			}

			const res = await axios
				.post(`/system/generateuseranssid?${query}`, {
					lgaId,
					lgaCode: lgaId,
					lgaSerial: lgaId
				})
				.then((res) => (res.data || {}).data);

			if (res.anssid) {
				try {
					await axios.post(`api/anssidApprove`, {
						Approveflg: "1",
						Authoriser: "001c",
						Comment: "Approved",
						anssidCode: res.anssid
					});
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.msg ||
								error.response.data?.message ||
								error.response.data?.errors?.[0] ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.message
						});
					}
				}
			}

			if (userInfo.account_type === "individual") {
				if (res.anssid && !userInfo.anssid) {
					localStorage.setItem(
						"user_info",
						JSON.stringify({ ...userInfo, anssid: res.anssid })
					);
				}
			}

			return res.anssid;
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors ||
						error.response.data?.error
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const convertImgToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64String = reader.result;
				resolve(base64String);
			};
			reader.onerror = (error) => {
				reject(error);
			};
			reader.readAsDataURL(file);
		});
	};

	const submit = async (e) => {
		e.preventDefault();
		setFetching(true);

		try {
			await axios
				.post(
					`/admin/individual-taxpayer/individualsinglepagetin/${userInfo.id}`,
					{
						nin,
						tin: TIN,
						title,
						firstname: firstName,
						middlename: middleName,
						lastname: lastName,
						gender,
						dob: dateOfBirth,
						phoneno: phone,
						emailaddr: email,
						countryorigin: countryOfOrigin,
						stateorigin: stateOfOrigin,
						lgaorigin: LGAOfOrigin,
						zoneorigin: zoneOfOrigin,
						residentialaddr: residentialAddress,
						residentialstate: stateOfResidence,
						residentiallga: LGAofResidence,
						residentialzone: zoneOfResidence,
						occupation,
						passportphoto: passport,
						inputter: email
					}
				)
				.then((res) => res.data);

			generateESBN().then((ESBN) => {
				history.push("/IM-success", ESBN);
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	const adminSubmit = async (e) => {
		e.preventDefault();
		setFetching(true);

		try {
			const res = await axios
				.post(
					`/admin/individual-taxpayer/addadminindividualsinglepagetin`,
					{
						nin,
						tin: TIN,
						title,
						firstname: firstName,
						middlename: middleName,
						lastname: lastName,
						gender,
						dob: dateOfBirth,
						phoneno: phone,
						emailaddr: email,
						countryorigin: countryOfOrigin,
						stateorigin: stateOfOrigin,
						lgaorigin: LGAOfOrigin,
						zoneorigin: zoneOfOrigin,
						residentialaddr: residentialAddress,
						residentialstate: stateOfResidence,
						residentiallga: LGAofResidence,
						residentialzone: zoneOfResidence,
						occupation,
						passportphoto: passport,
						inputter: userInfo?.email
					}
				)
				.then((res) => res.data);

			history.push("/IM-success", res.anssid);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	const adminEdit = async (e) => {
		e.preventDefault();
		setFetching(true);

		try {
			const res = await axios
				.put(
					`/admin/individual-taxpayer/editadminindividualsinglepagetin/${locationState?.UserId}`,
					{
						nin,
						tin: TIN,
						title,
						firstname: firstName,
						middlename: middleName,
						lastname: lastName,
						gender,
						dob: dateOfBirth,
						phoneno: phone,
						emailaddr: email,
						countryorigin: countryOfOrigin,
						stateorigin: stateOfOrigin,
						lgaorigin: LGAOfOrigin,
						zoneorigin: zoneOfOrigin,
						residentialaddr: residentialAddress,
						residentialstate: stateOfResidence,
						residentiallga: LGAofResidence,
						residentialzone: zoneOfResidence,
						occupation,
						passportphoto: passport,
						modifier: userInfo?.email
					}
				)
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.message || res.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	useEffect(() => {
		if (typeof passport === String) return;
		else {
			let convert = async () => {
				let converted = await convertImgToBase64(passport);
				setPassport(converted);
			};

			convert();
		}
	}, [passport]);

	return (
		<div className={styles.signup_page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside />
			<main className={styles.auth__form__container}>
				{userInfo?.account_type !== "individual" && <GoBackButton />}
				<h1>Individual Information</h1>
				<div className={styles.body}>
					{!locationState?.view && (
						<>
							<p>
								Please fill in your correct details in ALL the
								fields on this screen and click "Submit" to get
								your ESBN.
							</p>
							<hr className={styles.hr} />
						</>
					)}
					<img
						src={passport || profileImage}
						className={styles.profile__img}
						alt="individual taxpayer"
					/>
					{!locationState?.view && (
						<button
							type="button"
							className={`${styles.take__photo_btn} secondary__btn`}
							onClick={() => {
								showCameraContainer();
							}}
						>
							Take Photo
						</button>
					)}
					{!locationState?.view && (
						<p>
							<b>OR</b>
						</p>
					)}
					{showCaptureModal && (
						<Modal
							toggle={hideCameraContainer}
							showing={showCaptureModal}
						>
							<div
								className={styles.camera__area}
								ref={cameraContainer}
								id="main_video_capture_container"
							>
								<div>
									<span>
										<b>Note: </b>
										<span>
											Please note that profiles with wrong
											passport images will be suspended.
										</span>
									</span>
									<h4 className="text-center">
										Video Coming From Webcam{" "}
										<span id="detect_mobile"></span>
									</h4>
									<div id="main_video_capture_container_sub">
										<h1
											id="detectingFace"
											className="detecting_Face"
										>
											Detecting Face [
											<span id="detecting_Face_Counter">
												0
											</span>
											]<br />
											Please Position And Steady Your Face
											Within the <br />
											<b>Red Dotted Box</b> To Capture.
										</h1>
									</div>
									{/* {isCameraOpen && (
										<div
											className={styles.camera__box}
											style={{
												width: "300px",
												height: "400px"
											}}
										>
											<div
												style={{
													position: "relative",
													width: "100%",
													height: "100%",
													borderRadius: "8px"
												}}
											>
												<img
													className={styles.preview}
													id="inputImg"
													src={passport}
													alt="passport"
												/>
												<canvas
													style={{
														position: "absolute",
														top: 0,
														left: 0,
														display: "inline-block",
														verticalAlign:
															"baseline"
													}}
													className={
														styles.mobile__canvas
													}
													id="overlay"
												/>
											</div>
										</div>
									)} */}
									<div className="video-container">
										<div className="video-snap-top-container" />
										<div className="video-snap-left-container" />
										<div className="video-snap-right-container" />
										<div className="video-snap-bottom-container" />
										<div
											className={
												styles.video__snap_position
											}
										/>
										<video
											ref={videoEle}
											id="video"
											playsInline
											autoPlay
											muted
											style={{
												width: "100%",
												height: "100%"
											}}
										/>
										<canvas
											id="canvasFaceDetector"
											className={styles.canvas__play}
										/>
									</div>
									<button
										type="button"
										className={`primary__btn ${styles.capture__btn}`}
										id="btnCapture"
										onClick={captureImage}
									>
										CAPTURE PASSPORT
									</button>
								</div>
								<div>
									<h4>Captured Image From Webcam</h4>
									<canvas
										ref={canvasEle}
										style={{
											border: "solid 1px #ddd",
											backgroundColor: "white",
											boxShadow: "0px 0px 7px 4px #ccc"
										}}
										id="canvas1"
										className={styles.picture__preview}
										width={300}
										height={343}
									/>
									<button
										type="button"
										className={`tertiary__btn ${styles.clear__btn}`}
										onClick={clearCanvasCamera}
									>
										CLEAR CAPTURED PASSPORT
									</button>
								</div>
							</div>
							<button
								type="button"
								className={`secondary__btn ${styles.continue__btn}`}
								onClick={hideCameraContainer}
								id="closeAndContinue1"
							>
								Continue
							</button>
						</Modal>
					)}
					<form
						style={{ marginTop: "15px" }}
						className={styles.form}
						onSubmit={
							userInfo?.account_type === "individual"
								? submit
								: locationState?.edit
									? adminEdit
									: adminSubmit
						}
					>
						{!locationState?.view && (
							<>
								<div className={styles.double__inputs}>
									<div>
										<label htmlFor="passport">
											Upload Passport Photograph{" "}
											{!locationState?.edit && (
												<span style={{ color: "red" }}>
													*
												</span>
											)}
										</label>
										<input
											id="passport"
											name="passport"
											type="file"
											onChange={(e) => {
												setPassport(e.target.files[0]);
											}}
											required={!passport ? true : false}
											disabled={locationState?.view}
										/>
									</div>
								</div>
								<hr className={styles.hr} />
							</>
						)}
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="TIN">
									Tax Identification Number (TIN){" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="TIN"
									name="TIN"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setTIN(e.target.value);
										if (!e.target.value && tinError)
											setTinError("");
									}}
									value={TIN}
									disabled={locationState?.view}
									required
								/>
								{checkingTINRecord && (
									<p
										style={{
											fontSize: "12px",
											margin: "-25px 0 0",
											color: "#9e9e9e"
										}}
									>
										checking record...
									</p>
								)}
							</div>
							<div>
								<label htmlFor="nin">NIN</label>
								<input
									id="nin"
									name="nin"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setNIN(e.target.value);
									}}
									value={nin}
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<hr className={styles.hr} />
						<div>
							<label htmlFor="title">
								Title <span style={{ color: "red" }}>*</span>
							</label>
							<select
								id="title"
								name="title"
								onChange={(e) => {
									setTitle(e.target.value);
								}}
								value={title}
								required
								disabled={locationState?.view}
							>
								<option value="">---Select Title---</option>
								{titles.map((title) => (
									<option key={title.Description}>
										{title.Description}
									</option>
								))}
							</select>
						</div>
						<div className={styles.triple__inputs}>
							<div>
								<label htmlFor="lastname">
									Last Name{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="lastname"
									name="lastname"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setLastName(e.target.value);
									}}
									value={lastName}
									required
									disabled={locationState?.view}
								/>
							</div>
							<div>
								<label htmlFor="firstname">
									First Name{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="firstname"
									name="firstname"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setFirstName(e.target.value);
									}}
									value={firstName}
									required
									disabled={locationState?.view}
								/>
							</div>
							<div>
								<label htmlFor="middleName">Middle Name</label>
								<input
									id="middleName"
									name="middleName"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setMiddleName(e.target.value);
									}}
									value={middleName}
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<hr className={styles.hr} />
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="phone">
									Phone{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="phone"
									name="phone"
									type="tel"
									placeholder="enter..."
									onChange={(e) => {
										setPhone(e.target.value);
									}}
									value={phone}
									required
									disabled={
										locationState?.view ||
										locationState?.edit
									}
								/>
							</div>
							<div>
								<label htmlFor="email">
									Email{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="email"
									name="email"
									type="email"
									placeholder="enter..."
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									value={email}
									required
									disabled={
										locationState?.view ||
										locationState?.edit
									}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="gender">
									Gender{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="gender"
									name="gender"
									onChange={(e) => {
										setGender(e.target.value);
									}}
									value={gender}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Select Gender---
									</option>
									<option>Female</option>
									<option>Male</option>
									<option>Others</option>
								</select>
							</div>
							<div>
								<label htmlFor="dateOfBirth">
									Date of Birth{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="dateOfBirth"
									name="dateOfBirth"
									type="date"
									onChange={(e) => {
										setDateOfBirth(e.target.value);
										if (
											TIN &&
											e.target.value
												.split("-")
												.reverse()
												.join("-") !==
												taxpayerTINRecord?.date_of_birth
										)
											setAlert({
												...alert,
												showing: true,
												type: "warning",
												message:
													"Warning: DoB entered is different from that on TIN record"
											});
									}}
									value={dateOfBirth}
									required
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="occupation">
									Occupation{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="occupation"
									name="occupation"
									onChange={(e) => {
										setOccupation(e.target.value);
									}}
									value={occupation}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Select Occupation---
									</option>
									{occupations.map((occupation) => (
										<option key={occupation.Description}>
											{occupation.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						<hr className={styles.hr} />
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="countryOfOrigin">
									Country of Origin{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="countryOfOrigin"
									name="countryOfOrigin"
									onChange={(e) => {
										setCountryOfOrigin(e.target.value);
									}}
									value={countryOfOrigin}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Country of Origin---
									</option>
									{countryKeys.map((key) => (
										<option
											value={countries[key].country}
											key={key}
										>
											{titleCase(
												countries[
													key
												].country.toLowerCase()
											)}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="stateOfOrigin">
									State of Origin{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="stateOfOrigin"
									name="stateOfOrigin"
									onChange={(e) => {
										setStateOfOrigin(e.target.value);
									}}
									value={stateOfOrigin}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---State of Origin---
									</option>
									{countryOfOrigin === "Nigeria" ? (
										states.map((state) => (
											<option key={state.Code}>
												{state.Description}
											</option>
										))
									) : (
										<option value="Outside-Nigeria">
											Outside-Nigeria
										</option>
									)}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="LGAOfOrigin">
									LGA of Origin{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="LGAOfOrigin"
									name="LGAOfOrigin"
									onChange={(e) => {
										setLGAOfOrigin(e.target.value);
									}}
									value={LGAOfOrigin}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---LGA of Origin---
									</option>
									{countryOfOrigin === "Nigeria" ? (
										LGAs?.filter(
											(each) =>
												each.StateCode ===
												selectedStateCode
										).map((LGA) => (
											<option key={LGA.Code}>
												{LGA.Description}
											</option>
										))
									) : (
										<option value="N/A">
											Not Applicable
										</option>
									)}
								</select>
							</div>
							<div>
								<label htmlFor="zoneOfOrigin">
									Zone of Origin{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="zoneOfOrigin"
									name="zoneOfOrigin"
									onChange={(e) => {
										setZoneOfOrigin(e.target.value);
									}}
									value={zoneOfOrigin}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Zone of Origin---
									</option>
									{stateOfOrigin === "Enugu" ? (
										zonesOfOrigin?.map((zone) => (
											<option key={zone.Code}>
												{zone.Description}
											</option>
										))
									) : (
										<option value="N/A">
											Not Applicable
										</option>
									)}
								</select>
							</div>
						</div>
						<label htmlFor="residentialAddress">
							Residential Address{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="residentialAddress"
							name="residentialAddress"
							type="text"
							placeholder="enter..."
							onChange={(e) => {
								setResidentialAdress(e.target.value);
							}}
							value={residentialAddress}
							required
							disabled={locationState?.view}
						/>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="stateOfResidence">
									State of Residence{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="stateOfResidence"
									name="stateOfResidence"
									onChange={(e) => {
										setStateOfResidence(e.target.value);
									}}
									value={stateOfResidence}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---State of Residence---
									</option>
									{states.map((state) => (
										<option key={state.Code}>
											{state.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="LGAofResidence">
									LGA of Residence{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="LGAofResidence"
									name="LGAofResidence"
									onChange={(e) => {
										setLGAofResidence(e.target.value);
									}}
									value={LGAofResidence}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---LGA of Residence---
									</option>
									{LGAs.filter(
										(each) =>
											each.StateCode ===
											selectedStateOfResCode
									).map((LGA) => (
										<option key={LGA.Code}>
											{LGA.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="zoneOfResidence">
									Zone of Residence{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="zoneOfResidence"
									name="zoneOfResidence"
									onChange={(e) => {
										setZoneOfResidence(e.target.value);
									}}
									value={zoneOfResidence}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Zone of Residence---
									</option>
									{stateOfResidence === "Enugu" ? (
										zonesOfResidence?.map((zone) => (
											<option key={zone.Code}>
												{zone.Description}
											</option>
										))
									) : (
										<option value="N/A">
											Not Applicable
										</option>
									)}
								</select>
							</div>
						</div>
						{!locationState?.view && (
							<div className={styles.btn__div}>
								<button
									type="button"
									className="secondary__btn"
									onClick={
										userInfo?.account_type !== "individual"
											? () =>
													history.push(
														"/statetin/individual"
													)
											: () => history.push("/home")
									}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="primary__btn"
									disabled={
										!TIN ||
										(TIN && tinError) ||
										(TIN && TIN?.length < 10) ||
										!title ||
										!firstName ||
										!lastName ||
										!countryOfOrigin ||
										!stateOfOrigin ||
										!residentialAddress ||
										!stateOfResidence ||
										!LGAofResidence ||
										!zoneOfResidence ||
										!phone ||
										!email ||
										!gender ||
										!dateOfBirth ||
										!occupation ||
										!passport ||
										submitting
									}
								>
									{submitting ? "Submitting..." : "Submit"}
								</button>
							</div>
						)}
					</form>
				</div>
			</main>
		</div>
	);
};

export default IndividualInformation;
